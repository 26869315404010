
<template>
    <div class="queryMakeBox">
        <van-nav-bar title="危险品实验申请" safe-area-inset-top  placeholder fixed  left-text="返回" left-arrow @click-left="$router.push('/home')" /> 
        <div class="BXI">
             <van-tabs type="card" color='#1989fa' animated sticky offset-top='1.22667rem' v-model='DA_AuditStatus' @change="ChageTabs">
                <van-tab title="正在申请中" :name="0">
                    <list-conpents ref="applyIng" :DA_AuditStatus='DA_AuditStatus'></list-conpents>
                </van-tab>
                <van-tab title="已完成申请" :name="1">
                    <list-conpents ref="apply" :DA_AuditStatus='DA_AuditStatus'></list-conpents>
                </van-tab>
            
            </van-tabs>
        </div>
        <div class="BtnBox">
            <van-button type="danger" block @click="$router.push('/dangerAdd')">危险品申请</van-button>
        </div>
    </div>
</template>
<script>
import { NavBar,Tab, Tabs ,List, Image as VanImage,Button } from 'vant';
import ListConpents from './components/ListComponents'
export default {
    components:{
        [NavBar.name]: NavBar,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [List.name]: List,
        [VanImage.name]: VanImage,
        [Button.name]: Button,
        ListConpents
    },
    data() {
        return {
           DA_AuditStatus:0,
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.$refs.applyIng.CheckList()
        })
     
    },
    methods:{
        ChageTabs(val){
            if(val === 0){
                this.$nextTick(()=>{
                     this.$refs.applyIng.CheckList()
                })
               
            }else {
                this.$nextTick(()=>{
                    this.$refs.apply.CheckList()
                })
            }
        },
    }
}
</script>
<style lang="scss" scoped>
    .queryMakeBox {
        display: flex;
        flex-direction: column;
        height: 100vh;
    
        .BXI {
            background: #f2f2f2;
            flex: 1;
            overflow-y: auto;
            padding-bottom: 8px;
        }
        .van-tabs--card {
            /deep/ .van-tabs__wrap {
                height: 35px;
            }
            /deep/ .van-tabs__nav--card {
                margin: 0;
                height: 35px;
                line-height: 35px;
            }
        }
        .BtnBox {
            padding: 10px 14px;
            background-color: #fff;
            .van-button {
                line-height: 38px;
                height: 38px;
            }
        }
    }
    
</style>